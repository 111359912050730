import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import i18n, { BASE_URL } from '../../../i18n';

import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import AuthMenu from './AuthMenu/AuthMenu';
import WhoWeAreMenu from './WhoWeAreMenu/WhoWeAreMenu';
import WhatsHappeningMenu from './WhatsHappeningMenu/WhatsHappeningMenu';
import SuggestionMenu from './SuggestionMenu/SuggestionMenu';

import { isMediumViewSelector } from '../../../reducers/ui';
import Hamburger from './Hamburger/Hamburger';
import CloseButton from '../../ModalsContainer/CloseButton/CloseButton';
import './menu.css';

const Menu = ({ isHomePageMenu = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isMediumView = useSelector(isMediumViewSelector);

  const onHamburgerClick = useCallback(() => {
    setIsCollapsed(prevState => !prevState);
  }, []);

  const closeMobileMenu = useCallback(() => {
    setIsCollapsed(false);
  }, []);

  return (
    <>
      <div
        className={classNames('lg:hidden flex justify-start', {
          'py-6': isHomePageMenu
        })}
      >
        <Hamburger onClickHandler={onHamburgerClick} />
      </div>

      {(!isMediumView || (isMediumView && isCollapsed)) && (
        <nav className="home-nav flex py-2">
          <WhoWeAreMenu afterClickCallback={closeMobileMenu} />
          <Link
            to={BASE_URL + "/e-catalog"}
            className="px-3 py-2"
            onClick={closeMobileMenu}
          >
            {i18n.t('menu_e_catalog')}
          </Link>
          <WhatsHappeningMenu afterClickCallback={closeMobileMenu} />
          <Link
            to={BASE_URL + "/statistics"}
            className="px-3 py-2"
            onClick={closeMobileMenu}
          >
            {i18n.t('menu_statistics')}
          </Link>
          <SuggestionMenu afterClickCallback={closeMobileMenu} />
          <AuthMenu afterClickCallback={closeMobileMenu} />
          <LanguageSwitcher />

          {(isMediumView && isCollapsed) && (
            <CloseButton
              closeCb={closeMobileMenu}
              className="right-4 top-4 w-6 h-6"
            />
          )}
        </nav>
      )}
    </>
  );
};

export default Menu;
