import { emailRegexp } from '../constants/regex';

export const validateEmailValue = value => {
  return emailRegexp.test(value);
};

export const validatePassword = password => {
  const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  return re.test(password);
};
