import { ReactComponent as EmailIcon } from '../../../assets/icons/email-icon.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/link-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone-icon.svg';

import './footerInfo.css';

const FooterInfo = () => {
  const email = 'info@digital-armenia.am';
  const websiteLink = 'https://www.digital-armenia.am';
  const phoneNumber1 = '+374 15 333 444';
  const phoneNumber2 = '+374 93 344 433';

  return (
    <div className="flex-center flex-col md:flex-row py-3">
      <div className="flex-center w-full md:w-1/3 py-3 footer-info-item self-end">
        <div className="flex-center">
          <EmailIcon className="pr-3" />
          <a href={`mailto:${email}`} className="text-xl text-white">{email}</a>
        </div>
      </div>

      <div className="flex-center w-full md:w-1/3 py-3 footer-info-item">
        <div className="flex-center">
          <LinkIcon className="pr-3" />
          <a href={websiteLink} className="text-xl text-white">{websiteLink}</a>
        </div>
      </div>

      <div className="flex-center w-full md:w-1/3 py-3 self-start">
        <div className="flex-center">
          <PhoneIcon className="pr-3" />
          <div className="flex flex-col">
            <a href={`tel:${phoneNumber1}`} className="text-xl text-white">{phoneNumber1}</a>
            <a href={`tel:${phoneNumber2}`} className="text-xl text-white">{phoneNumber2}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
