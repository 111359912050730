import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import CloseButton from '../CloseButton/CloseButton';
import SuggestionFormWrapper from './SuggestionFormWrapper/SuggestionFormWrapper';

const SuggestionModal = ({ closeCb }) => {
  return (
    <ModalWrapper closeCb={closeCb}>
      <CloseButton closeCb={closeCb} />
      <SuggestionFormWrapper />
    </ModalWrapper>
  );
};

export default SuggestionModal;
