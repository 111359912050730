import { useEffect } from 'react';
import { authorized } from '../../services/httpClient';

const useHandleToken = () => {
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      authorized(token);
    }
  }, []);
};

export default useHandleToken;
