import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import SuggestionForm from '../../../SuggestionForm/SuggestionForm';
import { closeModal } from '../../../../actions/uiActions';
import i18n from '../../../../i18n';

import './suggestionFormWrapper.css';
import StatusPageWrapper from '../../../../pages/Auth/StatusPageWrapper/StatusPageWrapper';

const SuggestionFormWrapper = () => {
  const dispatch = useDispatch();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const afterSubmitCallback = useCallback(() => {
    setShowSuccessMessage(true);

    setTimeout(() => {
      dispatch(closeModal());
    }, 4000);
  }, [dispatch]);

  return (
    <div className="suggestion-content">
      <div className="suggestion-banner flex-center p-2">
        {
          showErrorMessage
            ? <p className="text-lg text-red-500 text-center">{i18n.t('suggestion_error')}</p>
            : <p className="text-lg text-white text-center">{i18n.t('suggestion_title')}</p>
        }
      </div>
      <div className="w-full pt-7.5">
        {showSuccessMessage && (
          <div className="pb-7.5">
            <StatusPageWrapper>
              <h2 className="mt-6 text-white text-2xl text-center px-5">
                {i18n.t('suggestion_success')}
              </h2>
            </StatusPageWrapper>
          </div>
        )}
        {!showSuccessMessage && (
          <SuggestionForm
            setShowErrorMessage={setShowErrorMessage}
            afterSubmitCallback={afterSubmitCallback}
          />
        )}
      </div>
    </div>
  );
};

export default SuggestionFormWrapper;
