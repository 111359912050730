import { useCallback, useState } from 'react';
import Popover from '@mui/material/Popover';
import { Link } from 'react-router-dom';

import i18n, { BASE_URL } from '../../../../i18n';

const WhatsHappeningMenu = ({ afterClickCallback }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickMenu = useCallback(() => {
    setAnchorEl(null);
    afterClickCallback();
  }, [afterClickCallback]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-what-happening' : undefined;

  return (
    <>
      <button
        type="button"
        className="px-3 py-2"
        aria-describedby={id}
        onClick={handleClick}
      >
        {i18n.t('menu_whats_happening')}
      </button>
      <Popover
        id={id}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col bg-white rounded-sm" style={{ minWidth: '165px' }}>
          <Link
            to={`${BASE_URL}/whats-happening/overview`}
            className="dropdown-menu__item py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleClickMenu}
          >
            {i18n.t('menu_whats_happening')}
          </Link>
          <Link
            to={`${BASE_URL}/whats-happening/our-events`}
            className="py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleClickMenu}
          >
            {i18n.t('menu_our_events')}
          </Link>
        </div>
      </Popover>
    </>
  );
};

export default WhatsHappeningMenu;
