export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_WINDOW_DIMENSION = 'SET_WINDOW_DIMENSION';

export const openModal = payload => ({
  type: OPEN_MODAL,
  payload,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const setWindowDimensions = payload => ({
  type: SET_WINDOW_DIMENSION,
  payload,
});
