import Modal from 'react-modal';
import classNames from 'classnames';

import './modalWrapper.css';

const ModalWrapper = (
  {
    headerId,
    classes = '',
    closeCb = () => {},
    children,
  }
) => {
  return (
    <Modal
      isOpen
      onRequestClose={closeCb}
      appElement={document.getElementById('root')}
      overlayClassName="modal-overlay"
      className={classNames('modal-content-custom pt-2', classes)}
      aria={{
        labelledby: headerId,
      }}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
