import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BASE_URL } from '../../../i18n';
import { ReactComponent as LogoIcon } from '../../../assets/logo.svg';
import Menu from '../Menu/Menu';
import SearchInput from '../SearchInput/SearchInput';

import { isMediumScreenWidthSelector } from '../../../reducers/ui';
import '../header.css';
import './mainHeader.css';
import ROUTE_PATH from '../../../constants/paths';

const MainHeader = () => {
  const isMediumScreen = useSelector(isMediumScreenWidthSelector);
  const { pathname } = useLocation();

  const shouldHideSearch = useMemo(() => {
    const pathItems = pathname.split('/');
    return ROUTE_PATH.ECatalog.includes(pathItems[2]) && pathItems.length !== 4;
  }, [pathname]);

  return (
    <div className="main-header flex-center">
      <div className="w-full max-w-screen-xl px-4 xl:px-0">
        <div className="flex justify-between">
          <Link
            to={BASE_URL}
            className="main-logo"
            aria-label='home page'
          >
            <LogoIcon className="w-full h-full" />
          </Link>
          <div className="flex items-center">
            {!isMediumScreen && (
              <div className="order-2 lg:order-1 pl-4 lg:pl-0">
                <Menu />
              </div>
            )}
            {!shouldHideSearch && (
              <div className="order-1 lg:order-2">
                <SearchInput isPrimary={false} className="ml-2" />
              </div>
            )}
          </div>
        </div>

        {isMediumScreen && (
          <div className="pt-2 flex justify-end">
            <Menu />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainHeader;
