import httpClient from "../services/httpClient";
import endpoints from "../services/httpClient/constants/endpoints";
import i18n from '../i18n';

export const SET_STATISTICS = 'SET_STATISTICS';
export const SET_STATISTICS_LOADING = 'SET_STATISTICS_LOADING';
export const SET_STATISTICS_ERROR = 'SET_STATISTICS_ERROR';

export const setStatistics = payload => ({
  type: SET_STATISTICS,
  payload,
});

export const setStatisticsLoading = payload => ({
  type: SET_STATISTICS_LOADING,
  payload,
});

export const setStatisticsError = payload => ({
  type: SET_STATISTICS_ERROR,
  payload,
});

export const getStatistics = () => async (dispatch, getState) => {
  try {
    dispatch(setStatisticsLoading(true));

    const statistics = await httpClient.get(endpoints.statistics, {
      params: {
        language_prefix: i18n.language,
      }
    });

    dispatch(setStatistics(statistics));
  } catch (error) {
    dispatch(setStatisticsError(error));
  } finally {
    dispatch(setStatisticsLoading(false));
  }
}; 