import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import i18n, { BASE_URL } from '../../../i18n';
import { ReactComponent as LogoIcon } from '../../../assets/logo.svg';
import { ReactComponent as LogoUntitledIcon } from '../../../assets/logo-untitled.svg';
import Menu from '../Menu/Menu';
import MainHeader from '../MainHeader/MainHeader';

import SearchInput from '../SearchInput/SearchInput';
import {
  isMediumViewSelector,
  widthSelector,
} from '../../../reducers/ui';
import '../header.css';
import './homePageHeader.css';
import canvasSupport from '../../../utils/modernizr';
import sphereAnimation from '../../../utils/animation';
import { MEDIUM_WINDOW_WIDTH } from '../../../constants/windowSize';

const HomePageHeader = () => {
  const [shouldShowHomeMenu, setShouldShowHomeMenu] = useState(true);
  const [hasCanvasSupport, setHasCanvasSupport] = useState(true);
  const screenWidth = useSelector(widthSelector);
  const isMediumView = useSelector(isMediumViewSelector);

  const timeoutRef = useRef(null);

  const canvasWidth = useMemo(() => {
    if (screenWidth < MEDIUM_WINDOW_WIDTH) {
      return screenWidth;
    }

    if (screenWidth >= MEDIUM_WINDOW_WIDTH && screenWidth < 1023) {
      return 991;
    }

    return 1023;
  }, [screenWidth]);

  const animationCenterX = useMemo(() => (
    window.innerWidth >= MEDIUM_WINDOW_WIDTH
      ? (canvasWidth / 3)
      : canvasWidth / 2
  ), [canvasWidth]);

  // 126/2 is the logo's width
  const logoStyle = useMemo(() => ({
    marginLeft: window.innerWidth >= MEDIUM_WINDOW_WIDTH ? `${animationCenterX - 126/2}px` : 0,
  }), [animationCenterX]);

  useEffect(() => {
    const handleScroll = event => {
      setShouldShowHomeMenu(window.scrollY <= 120);
    };

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    };
  }, []);

  useLayoutEffect(() => {
    if (canvasWidth) {
      if (!canvasSupport()) {
        setHasCanvasSupport(false);
        return;
      }

      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        sphereAnimation();
      }, 1000);
    }
  }, [canvasWidth]);

  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  }, []);

  return (
    <div className="bg-primary-700">
      <div className="home-page-header flex-center">
        <div className="home-page-header__bg" />
        {hasCanvasSupport && (
          <div className="home-page-header__canvas-container page-container">
            <canvas
              id="animationCanvas"
              className="home-page-header__canvas"
              width={canvasWidth}
              height="800"
            />
          </div>
        )}
        <div className="page-container z-2">
          <div className="home-page-header__content pl-0">
            <LogoUntitledIcon style={logoStyle} />
            <div className="flex flex-col mt-4 lg:mt-0 ml-4 text-center md:text-left">
              <h1 className="text-3xl text-white pb-2">{i18n.t('home_header_text')}</h1>
              <h1 className="text-3xl text-white pt-2">{i18n.t('home_header_subtext')}</h1>
            </div>
          </div>
          {
            shouldShowHomeMenu
              ? (
                <>
                  <div className="home-page-header__menu-content">
                    <div className="flex items-center justify-between">
                      <Link
                        to={BASE_URL}
                        className="main-logo"
                        aria-label='home page'
                      >
                        <LogoIcon className="w-full h-full" />
                      </Link>
                      <div className="flex items-center justify-between">
                        <SearchInput isPrimary className="pr-0 sm:pr-2" />
                        {isMediumView && (
                          <div className="px-2">
                            <Menu isHomePageMenu />
                          </div>
                        )}
                      </div>
                    </div>
                    {!isMediumView && (
                      <div className="flex flex-col justify-end">
                        <Menu isHomePageMenu />
                      </div>
                    )}
                  </div>
                </>
              )
              : <MainHeader />
          }
        </div>
      </div>
    </div>
  );
};

export default HomePageHeader;
