import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import ROUTE_PATH from '../../../../../constants/paths';
import { BASE_URL } from '../../../../../i18n';

const AUTH_PAGES_PATHS = [
  ROUTE_PATH.Signup,
  ROUTE_PATH.ResetPassword,
  ROUTE_PATH.ConfirmAccount,
];

const useLoginRedirection = () => {
  const { pathname } = useLocation();

  return useCallback(() => {
    if (AUTH_PAGES_PATHS.some(path => pathname?.includes(path))) {
      window.location.assign(BASE_URL);
    } else {
      window.location.reload();
    }
  }, [pathname]);
};

export default useLoginRedirection;
