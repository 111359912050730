import { useCallback, useState } from 'react';
import { Link } from "react-router-dom";
import Popover from '@mui/material/Popover';

import i18n, { BASE_URL } from '../../../../i18n';

const WhoWeAreMenu = ({ afterClickCallback }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickMenu = useCallback(() => {
    setAnchorEl(null);
    afterClickCallback();
  }, [afterClickCallback]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-who-we-are' : undefined;

  return (
    <>
      <button
        type="button"
        className="px-3 py-2"
        aria-describedby={id}
        onClick={handleClick}
      >
        {i18n.t('menu_who_we_are')}
      </button>
      <Popover
        id={id}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col bg-white rounded-sm" style={{ maxWidth: '200px' }}>
          <Link
            to={`${BASE_URL}/who-we-are/our-team`}
            className="dropdown-menu__item py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleClickMenu}
          >
            {i18n.t('menu_our_team')}
          </Link>
          <Link
            to={`${BASE_URL}/who-we-are/what-we-think`}
            className="dropdown-menu__item py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleClickMenu}
          >
            {i18n.t('menu_what_we_think')}
          </Link>
          <Link
            to={`${BASE_URL}/who-we-are/services-and-products`}
            className="py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleClickMenu}
          >
            {i18n.t('menu_our_products_and_services')}
          </Link>
        </div>
      </Popover>
    </>
  );
};

export default WhoWeAreMenu;
