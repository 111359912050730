import { createSelector } from 'reselect';
import {
  SET_USER, SET_USER_ERROR,
  SET_USER_IS_FETCHING,
} from '../actions/userActions';
import USER_TYPE from '../constants/userType';

const initialState = {
  data: null,
  isFetching: true,
  error: null,
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        data: payload,
      };
    case SET_USER_IS_FETCHING:
      return {
        ...state,
        isFetching: payload,
      };
    case SET_USER_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default user;

export const userSelector = state => state.user;

export const userDataSelector = state => userSelector(state).data;

export const userNameSelector = state => userDataSelector(state)?.firstName;
export const userLastNameSelector = state => userDataSelector(state)?.lastName;
export const userTypeSelector = state => userDataSelector(state)?.type;
export const userEmailSelector = state => userDataSelector(state)?.email;
export const userTinSelector = state => userDataSelector(state)?.tin;
export const userCompanyNameSelector = state => userDataSelector(state)?.companyName;
export const userPhoneNumberSelector = state => userDataSelector(state)?.phoneNumber;
export const userStatusSelector = state => userDataSelector(state)?.status;
export const userHasPhotoSelector = state => userDataSelector(state)?.hasPhoto;
export const userPhotoSelector = state => userDataSelector(state)?.photo;
export const userLastLoginDateSelector = state => userDataSelector(state)?.lastLoginDate;

export const userFullNameSelector = createSelector(
  userTypeSelector,
  userNameSelector,
  userLastNameSelector,
  userCompanyNameSelector,
  (type, name, lastName, companyName) => {
    if (Number(type) === USER_TYPE.Personal) {
      return `${name} ${lastName}`;
    } else {
      return companyName;
    }
  },
);

export const userErrorSelector = state => userSelector(state).error;

export const isUserFetchingSelector = state => userSelector(state).isFetching;
