import httpClient from '../services/httpClient';
import endpoints from '../services/httpClient/constants/endpoints';

export const SET_USER = 'SET_USER';
export const SET_USER_IS_FETCHING = 'SET_USER_IS_FETCHING';
export const SET_USER_ERROR = 'SET_USER_ERROR';

export const setUser = payload => ({
  type: SET_USER,
  payload,
});

export const setUserIsFetching = payload => ({
  type: SET_USER_IS_FETCHING,
  payload,
});

export const setUserError = payload => ({
  type: SET_USER_ERROR,
  payload,
});

export const getMyProfile = (shouldSetIsFetching = true) => async dispatch => {
  if (shouldSetIsFetching) {
    dispatch(setUserIsFetching(true));
  }

  try {
    const profileData = await httpClient.get(endpoints.getMyProfile, { withCredentials: true });

    if (profileData) {
      const {
        first_name: firstName,
        last_name: lastName,
        type,
        email,
        company_name: companyName,
        tin,
        has_photo: hasPhoto,
        photo,
        phone_number: phoneNumber,
        status,
      } = profileData;

      const lastLoginDate = profileData.last_login_date?.date;

      dispatch(setUser({
        firstName,
        lastName,
        type,
        email,
        companyName,
        tin,
        hasPhoto,
        photo,
        phoneNumber,
        status,
        lastLoginDate,
      }));
    }
  } catch (error) {
    dispatch(setUserError(error));
  } finally {
    if (shouldSetIsFetching) {
      dispatch(setUserIsFetching(false));
    }
  }
};
