import { useCallback, useEffect, useState } from 'react';
import { FormGroup, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import Cookies from 'js-cookie';

import { validateEmailValue } from '../../../../utils/validator';
import i18n from '../../../../i18n';
import './loginForm.css';
import httpClient from '../../../../services/httpClient';
import endpoints from '../../../../services/httpClient/constants/endpoints';
import Loader from '../../../Loader/Loader';
import FORM_TYPE from '../constants/formType';
import COOKIE_KEYS from '../../../../constants/cookieKeys';
import useLoginRedirection from './useLoginRedirection/useLoginRedirection';

const LoginForm = ({ closeCb, switchForm }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isRemember, setIsRemember] = useState(false);

  const navigateAfterLogin = useLoginRedirection();

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setIsEmailInvalid(false);

    if (value.trim().length <= 250) {
      setEmail(value);
    }
  }, []);

  const handleEmailBlur = useCallback(() => {
    setIsEmailInvalid(!validateEmailValue(email.trim()));
  }, [email]);

  const handlePasswordChange = useCallback(({ target: { value } }) => {
    setIsPasswordInvalid(false);

    if (value.trim().length <= 250) {
      setPassword(value);
    }
  }, []);

  const handlePasswordBlur = useCallback(() => {
    setIsPasswordInvalid(!password.trim().length);
  }, [password]);

  const toggleRemember = () => {
    setIsRemember(prevState => !prevState);
  };

  const handleSubmit = useCallback(async e => {
    e.preventDefault();

    const emailValue = email.trim();
    const passwordValue = password.trim();

    if (
      validateEmailValue(emailValue)
      && passwordValue
    ) {
      try {
        Cookies.set(COOKIE_KEYS.loginEmail, emailValue);
        Cookies.set(COOKIE_KEYS.loginRememberMe, isRemember);

        setIsLoading(true);
        setErrorText(null);

        await httpClient.post(endpoints.login, {
          email: emailValue,
          password: passwordValue,
        });

        navigateAfterLogin();
      } catch (error) {
        setErrorText(i18n.t('generic_error_text'));
      } finally {
        setIsLoading(false);
      }
    }
  }, [email, password, isRemember, navigateAfterLogin]);

  useEffect(() => {
    const cookieEmail = Cookies.get(COOKIE_KEYS.loginEmail);
    const cookieRememberMe = Cookies.get(COOKIE_KEYS.loginRememberMe);

    if (
      cookieRememberMe === 'true'
      && cookieEmail
    ) {
      setEmail(cookieEmail);
      setIsRemember(true);
    }
  }, []);

  return (
    <div className="login-form-content">
      <div className="login-form-banner p-2">
        <p className="text-lg text-white text-center">{i18n.t('login_title')}</p>
      </div>
      <div className="w-full pt-7.5 p-6">
        {errorText && (
          <p className="text-sm text-red-500 text-center pb-4">{errorText}</p>
        )}
        <form>
          <FormGroup
            classes={{
              root: 'w-full mb-4',
            }}
          >
            <label
              htmlFor="loginEmail"
              className="text-primary-700 mb-1"
            >
              {i18n.t('login_email')}
            </label>
            <TextField
              id="loginEmail"
              variant="outlined"
              size="small"
              classes={{
                root: `form-input login-input ${isEmailInvalid ? 'form-input__invalid' : ''}`,
                popper: 'shadow-md',
              }}
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
            />
          </FormGroup>
          <FormGroup
            classes={{ root: 'w-full mb-4' }}
          >
            <label
              htmlFor="loginPassword"
              className="text-primary-700 mb-1"
            >
              {i18n.t('register_form_password')}
            </label>
            <TextField
              id="loginPassword"
              variant="outlined"
              type="password"
              size="small"
              classes={{
                root: `form-input login-input ${isPasswordInvalid ? 'form-input__invalid' : ''}`,
                popper: 'shadow-md',
              }}
              value={password}
              onChange={handlePasswordChange}
              onBlur={handlePasswordBlur}
            />
          </FormGroup>
          <button
            type="button"
            className="text-brown-100 text-xs uppercase flex w-full justify-end"
            onClick={() => switchForm(FORM_TYPE.ForgotPassword)}
          >
            {i18n.t('login_forgot_password')}
          </button>
          <FormGroup classes={{ root: '!inline-block mb-4' }}>
            <FormControlLabel
              checked={isRemember}
              control={<Checkbox />}
              label={i18n.t('login_remember')}
              onChange={toggleRemember}
            />
          </FormGroup>
          <div className="flex-center">
            <Button
              type="submit"
              id="loginSubmit"
              onClick={handleSubmit}
              classes={{ root: 'button-primary w-40 bg-blue-700' }}
            >
              {
                isLoading
                  ? <Loader diameter={30} borderWidth={3} />
                  : i18n.t('login')
              }
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
