import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

import hy from './locales/hy/translations.json';
import ru from './locales/ru/translations.json';
import en from './locales/en/translations.json';

const SUPPORTED_LANGUAGES = ['hy', 'ru', 'en'];

const cookieLang = Cookies.get('lang');

const handleInitialLocalePath = () => {
  const pathItems = window.location.pathname.split('/');
  const localePathItem = pathItems[1];

  if (!localePathItem || !SUPPORTED_LANGUAGES.includes(localePathItem)) {
    const lang = cookieLang || SUPPORTED_LANGUAGES[0];

    pathItems.splice(1, 1, lang);
    const newPath = pathItems.join('/');
    window.location.replace(newPath);
  }
}

i18next.on('initialized', (options) => {
  handleInitialLocalePath();

  i18next.on('languageChanged', function (lng) {
    if (i18next.options.whitelist.includes(lng)) {
      const pathItems = window.location.pathname.split('/');
      const searchItems = window.location.search;
      pathItems.splice(1, 1, lng);
      const newPath = pathItems.join('/');

      window.location.replace(`${newPath}${searchItems}`);
    }
  });
});

const resources = {
  hy: {
    translation: hy,
  },
  ru: {
    translation: ru,
  },
  en: {
    translation: en,
  },
};

i18next
  .use(LanguageDetector)
  .init({
    whitelist: SUPPORTED_LANGUAGES,
    fallbackLng: [cookieLang || 'hy'],
    resources,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      lookupCookie: 'lang',
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: '.'
    }
  });

export default i18next;

export const BASE_URL = `/${i18next.language}`;

export const DEFAULT_LANGUAGE = 'hy';
