const Hamburger = ({ onClickHandler }) => {
  return (
    <button
      type="button"
      className="space-y-2"
      onClick={onClickHandler}
    >
      <div className="w-8 h-0.5 bg-secondary-300" />
      <div className="w-8 h-0.5 bg-secondary-300" />
      <div className="w-8 h-0.5 bg-secondary-300" />
    </button>
  );
};

export default Hamburger;
