import './footer.css';
import FooterInfo from './FooterInfo/FooterInfo';
import FooterMain from './FooterMain/FooterMain';
import FooterCopyRight from './FooterCopyRight/FooterCopyRight';

const Footer = () => {
  return (
    <div className="bg-primary-700">
      <div className="page-container">
        <FooterInfo />
      </div>
      <FooterMain />
      <div className="page-container">
        <FooterCopyRight />
      </div>
    </div>
  );
};

export default Footer;
