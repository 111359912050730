import prodEnv from './environment.prod';
import devEnv from './environment.dev';

const environment = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'prod':
      return prodEnv;
    default:
      return devEnv;
  }
})();

export default environment;
