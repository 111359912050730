import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './appRoutes.css';
import in18n from '../i18n';
import { setSearchValue } from '../actions/searchActions';
import ROUTE_PATH from '../constants/paths';

const Home = React.lazy(() => import('../pages/Home/Home'));
const OurTeam = React.lazy(() => import('../pages/WhoWeAre/OurTeam/OurTeam'));
const WhatWeThink = React.lazy(() => import('../pages/WhoWeAre/WhatWeThink/WhatWeThink'));
const ServicesProducts = React.lazy(() => import('../pages/WhoWeAre/ServicesProducts/ServicesProducts'));
const ECatalog = React.lazy(() => import('../pages/ECatalog/ECatalog'));
const ECatalogDetails = React.lazy(() => import('../pages/ECatalogDetails/ECatalogDetails'));
const UpcomingEvents = React.lazy(() => import('../pages/WhatsHappening/UpcomingEvents/UpcomingEvents'));
const EventDetails = React.lazy(() => import('../pages/EventDetails/EventDetails'));
const OurEvents = React.lazy(() => import('../pages/WhatsHappening/OurEvents/OurEvents'));
const Register = React.lazy(() => import('../pages/Auth/Register/Register'));
const MyProfile = React.lazy(() => import('../pages/MyProfile/MyProfile'));
const ConfirmAccount = React.lazy(() => import('../pages/Auth/Register/ConfirmAccount/ConfirmAccount'));
const ResetPassword = React.lazy(() => import('../pages/Auth/ResetPassword/ResetPassword'));
const Statistics = React.lazy(() => import('../pages/Statistics/Statistics'));

const AppRoutes = () => {
  const baseRouteUrl = `/:locale`;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isHomePage = pathname === '/' || pathname === `/${in18n.language}`;

  useEffect(() => {
    // to remove global search value
    dispatch(setSearchValue(''));
  }, [pathname, dispatch]);

  return (
    <div
      className={classNames('main-routes-content flex-auto bg-white', {
        'main-routes-content__home': isHomePage
      })}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={baseRouteUrl} element={<Home />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.OurTeam}`} element={<OurTeam />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.WhatWeThink}`} element={<WhatWeThink />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.ServicesAndProduces}`} element={<ServicesProducts />} />
        <Route exact path={`${baseRouteUrl}${ROUTE_PATH.ECatalog}`} element={<ECatalog />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.ECatalogDetails}`} element={<ECatalogDetails />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.UpcomingEvents}`} element={<UpcomingEvents />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.OurEvents}`} element={<OurEvents />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.EventDetails}`} element={<EventDetails />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.Statistics}`} element={<Statistics />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.Signup}`} element={<Register />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.MyProfile}`} element={<MyProfile />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.ConfirmAccount}`} element={<ConfirmAccount />} />
        <Route path={`${baseRouteUrl}${ROUTE_PATH.ResetPassword}`} element={<ResetPassword />} />
        {/*<Route path="*" element={<Home />} />*/}
      </Routes>
    </div>

  );
};

export default AppRoutes;
