import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SuggestionModal from './SuggestionModal/SuggestionModal';
import LoginModal from './LoginModal/LoginModal';
import { closeModal } from '../../actions/uiActions';
import { openedModalTypeSelector } from '../../reducers/ui';
import MODAL_TYPE from '../../constants/modalType';

const ModalsContainer = () => {
  const dispatch = useDispatch();
  const modalType = useSelector(openedModalTypeSelector);

  const closeCb = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return useMemo(() => {
    switch (modalType) {
      case MODAL_TYPE.Suggestion:
        return <SuggestionModal closeCb={closeCb} />;
      case MODAL_TYPE.Login:
        return <LoginModal closeCb={closeCb} />;

      default:
        return null;
    }
  }, [modalType, closeCb]);
};

export default ModalsContainer;
