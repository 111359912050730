import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg';
import classNames from 'classnames';

import './closeButton.css';

const CloseButton = ({ closeCb, className = '' }) => (
  <button
    type="button"
    className={classNames('modal-close-button', className)}
    onClick={closeCb}
    aria-label="close modal button"
  >
    <CloseIcon className="close-button__icon" />
  </button>
);

export default CloseButton;
