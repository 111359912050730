import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormGroup, TextField, Button } from '@mui/material';

import i18n, { BASE_URL } from '../../../i18n';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { setSearchValue } from '../../../actions/searchActions';
import { searchValueSelector } from '../../../reducers/search';

import './searchInput.css';

const SearchInput = ({ isPrimary, className = '' }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchValue = useSelector(searchValueSelector);

  const placeholder = `${i18n.t('search')}...`;

  const handleSearch = useCallback(e => {
    e.preventDefault();

    if (!!searchValue.trim().length) {
      navigate(`${BASE_URL}/e-catalog?s=${searchValue.trim()}`);
    }
  }, [searchValue, navigate]);

  const handleSearchChange = ({ target: { value } }) => {
    const valueLength = value.trim().length;

    if (valueLength < 250) {
      dispatch(setSearchValue(value));
    }
  }

  return (
    <form onSubmit={handleSearch} className={className}>
      <FormGroup
        classes={{
          root: 'search-form-group',
        }}
        row
      >
        <TextField
          variant="outlined"
          size="small"
          placeholder={placeholder}
          classes={{
            root: `search-input ${isPrimary ? 'search-input__primary' : 'search-input__secondary'}`,
            popper: 'shadow-md',
          }}
          value={searchValue}
          onChange={handleSearchChange}
        />
        <Button
          variant="outlined"
          type="submit"
          size="small"
          aria-label="search input"
          classes={{
            root: `search-button border ${isPrimary ? 'search-button__primary' : 'search-button__secondary'}`
          }}
        >
          <SearchIcon />
        </Button>
      </FormGroup>
    </form>
  );
};

export default SearchInput;
