import EuropeanUnionImage from '../../../assets/footer/european-union-logo.jpeg';
import ArmImage from '../../../assets/footer/arm-logo.png';
import { ReactComponent as UndpImage } from '../../../assets/footer/undp.svg';
import { ReactComponent as KolbaImage } from '../../../assets/footer/kolba.svg';

import './footerCopyRight.css';
import i18n from '../../../i18n';

const FooterCopyRight = () => {
  const year = (new Date()).getFullYear();

  return (
    <div className="flex flex-col justify-between md:flex-row bg-primary-700 py-6">
      <div className="flex-center md:flex py-3 md:py-0">
        <div className="footer-logo-item__small mr-6 lg:mr-10">
          <img src={EuropeanUnionImage} alt="european union" />
        </div>
        <div className="footer-logo-item__small mr-6 lg:mr-10">
          <img src={ArmImage} alt="arm image" />
        </div>
        <div className="footer-logo-item mr-6 lg:mr-10">
          <KolbaImage className="kolba-image" />
        </div>
        <div className="footer-logo-item">
          <UndpImage />
        </div>
      </div>
      <div className="flex-center">
        <p className="footer-copyright-text">
          {i18n.t('footer_text')}
        </p>
      </div>
    </div>
  );
};

export default FooterCopyRight;
