import { useLocation } from "react-router-dom";

import in18n from '../../i18n';
import HomePageHeader from './HomePageHeader/HomePageHeader';
import MainHeader from './MainHeader/MainHeader';

const Header = () => {
  const { pathname } = useLocation();
  const isHomePage = pathname === '/' || pathname === `/${in18n.language}`;

  return (
    <div className="header-content w-full z-20">
      {
        isHomePage
          ? <HomePageHeader />
          : <MainHeader />
      }
    </div>
  );
};

export default Header;
