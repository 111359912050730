import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openModal } from '../../../../actions/uiActions';
import MODAL_TYPE from '../../../../constants/modalType';
import i18n from '../../../../i18n';

const SuggestionMenu = ({ afterClickCallback }) => {
  const dispatch = useDispatch();

  const openSuggestionModal = useCallback(() => {
    dispatch(openModal(MODAL_TYPE.Suggestion));
    afterClickCallback();
  }, [dispatch, afterClickCallback]);

  return (
    <button
      type="button"
      className="px-3 py-2"
      onClick={openSuggestionModal}
    >
      {i18n.t('menu_suggestion')}
    </button>
  );
};

export default SuggestionMenu;
