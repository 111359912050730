export const HTTP_ERROR_CODE = {
  NetworkError: -1,
  AbortError: -2,
  BadRequest: 400,
  Unauthenticated: 401,
  PaymentRequired: 402,
  Forbidden: 403,
  NotFound: 404,
  NotAcceptable: 406,
  Conflict: 409,
  PreconditionFail: 412,
  ServerError: 500,
};
