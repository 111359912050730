import { useCallback, useState } from 'react';

import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import CloseButton from '../CloseButton/CloseButton';
import LoginForm from './LoginForm/LoginForm';
import FORM_TYPE from './constants/formType';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';

const LoginModal = ({ closeCb }) => {
  const [activeForm, setActiveForm] = useState(FORM_TYPE.Login);

  const switchActiveForm = useCallback(formType => {
    setActiveForm(formType);
  }, []);

  return (
    <ModalWrapper closeCb={closeCb} classes='!p-0'>
      <CloseButton closeCb={closeCb} />
      {activeForm === FORM_TYPE.Login && (
        <LoginForm closeCb={closeCb} switchForm={switchActiveForm} />
      )}
      {activeForm === FORM_TYPE.ForgotPassword && (
        <ForgotPasswordForm switchForm={switchActiveForm} />
      )}
    </ModalWrapper>
  );
};

export default LoginModal;
