import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';

import i18n, { BASE_URL } from '../../../../../i18n';
import { ReactComponent as ProfileIcon } from '../../../../../assets/icons/profile-icon.svg';
import MY_PROFILE_TYPE from '../../../../../pages/MyProfile/constants/myProfile';
import httpClient from '../../../../../services/httpClient';
import endpoints from '../../../../../services/httpClient/constants/endpoints';

const MyProfileMenu = ({ afterClickCallback }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickMenu = useCallback(() => {
    setAnchorEl(null);
    afterClickCallback();
  }, [afterClickCallback]);

  const handleLogout = useCallback(async () => {
    try {
       await httpClient.delete(endpoints.logout);
       window.location.reload();
    } catch (error) {
      // handel error case
    } finally {
      // handle finally
    }

  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-auth' : undefined;


  return (
    <>
      <button
        type="button"
        className="flex-center"
        onClick={handleClick}
      >
        <ProfileIcon className="mr-1" />
        <span>{i18n.t('my_profile')}</span>
      </button>
      <Popover
        id={id}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="flex flex-col bg-white rounded-sm" style={{ minWidth: '165px' }}>
          <Link
            to={`${BASE_URL}/my-profile?page=${MY_PROFILE_TYPE.PersonalDetails}`}
            className="dropdown-menu__item py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleClickMenu}
          >
            {i18n.t('personal_cabinet_menu')}
          </Link>
          <Link
            to={`${BASE_URL}/my-profile?page=${MY_PROFILE_TYPE.Subscription}`}
            className="dropdown-menu__item py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleClickMenu}
          >
            {i18n.t('personal_cabinet_subscription')}
          </Link>
          <button
            type="button"
            className="text-left py-2 px-3 hover:bg-secondary-300 !text-primary-700 hover:!text-primary-700"
            onClick={handleLogout}
          >
            {i18n.t('logout')}
          </button>
        </div>
      </Popover>
    </>
  );
};

export default MyProfileMenu
