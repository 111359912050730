import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import i18n, { BASE_URL } from '../../../../i18n';
import { ReactComponent as ProfileIcon } from '../../../../assets/icons/profile-icon.svg';
import { ReactComponent as KeyIcon } from '../../../../assets/icons/key-icon.svg';
import { openModal } from '../../../../actions/uiActions';
import MODAL_TYPE from '../../../../constants/modalType';
import { userDataSelector } from '../../../../reducers/user';
import MyProfileMenu from './MyProfileMenu/MyProfileMenu';
import './authMenu.css';

const AuthMenu = ({ afterClickCallback }) => {
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);

  const openLoginModal = useCallback(() => {
    dispatch(openModal(MODAL_TYPE.Login));
    afterClickCallback();
  }, [dispatch, afterClickCallback]);

  return (
    <div className="flex px-3">
      {
        userData
          ? (
            <MyProfileMenu afterClickCallback={afterClickCallback} />
          )
          : (
            <>
              <button
                type="button"
                className="flex-center pr-3 login-menu-item"
                onClick={openLoginModal}
              >
                <ProfileIcon className="mr-1" />
                <span>{i18n.t('login')}</span>
              </button>
              <Link
                to={`${BASE_URL}/sign-up`}
                className="flex-center pl-3"
                onClick={afterClickCallback}
              >
                <KeyIcon className="mr-1" />
                <span>{i18n.t('registration')}</span>
              </Link>
            </>
          )
      }
    </div>
  );
};

export default AuthMenu;
