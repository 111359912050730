import { combineReducers } from 'redux';
import user from './user';
import search from './search';
import ui from './ui';
import statistics from './statistics';

export default combineReducers({
  user,
  search,
  ui,
  statistics,
});
