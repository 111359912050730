import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { ReactComponent as FbIcon } from '../../../assets/icons/fb-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/linkedin-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/youtube-icon.svg';
import './footerMain.css';
import i18n from '../../../i18n';

const facebookLink = 'https://www.facebook.com/Digital-Armenia-%D4%B9%D5%BE%D5%A1%D5%B5%D5%AB%D5%B6-%D5%80%D5%A1%D5%B5%D5%A1%D5%BD%D5%BF%D5%A1%D5%B6-109173568129931/';
const linkedinLink = 'https://www.linkedin.com/company/digital-armenia/';
const youtubeLink = 'https://www.youtube.com/channel/UCy_GGdbEwfGPGGq_tRavydA';

const FooterMain = () => {
  return (
    <div className="footer-main">
      <div className="page-container flex-center flex-col md:flex-row py-10">
        <div className="w-full md:w-1/3 py-5 md:py-0">
          <div className="flex flex-col px-4 xl:px-0">
            <div className="w-14 h-0.5 bg-secondary-700" />
            <h3 className="pt-4">
              {i18n.t('footer_main_text')}
            </h3>
          </div>
        </div>
        <div className="w-full md:w-1/3 py-5 md:py-0">
          <div className="flex-center">
            <Logo className="w-28" />
          </div>
        </div>
        <div className="w-full md:w-1/3 py-5 md:py-0">
          <div className="flex-center">
            <a
              href={facebookLink}
              target="_blank"
              rel="noreferrer"
              className="footer-main-icon flex-center"
            >
              <FbIcon />
            </a>
            <a
              href={linkedinLink}
              target="_blank"
              rel="noreferrer"
              className="footer-main-icon flex-center"
            >
              <LinkedinIcon />
            </a>
            <a
              href={youtubeLink}
              target="_blank"
              rel="noreferrer"
              className="footer-main-icon flex-center"
            >
              <YoutubeIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMain;
