import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_WINDOW_DIMENSION,
} from '../actions/uiActions';
import {
  EXTRA_SMALL_WIDTH,
  LARGE_WINDOW_WIDTH,
  MEDIUM_WINDOW_HEIGHT,
  MEDIUM_WINDOW_WIDTH,
  SMALL_WINDOW_WIDTH,
  TABLET_WINDOW_WIDTH,
} from '../constants/windowSize';

const initialState = {
  type: null,
  windowDimensions: {
    width: null,
    height: null,
  },
};

const ui = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL:
      return {
        ...state,
        type: payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        type: null,
      };
    case SET_WINDOW_DIMENSION:
      return {
        ...state,
        windowDimensions: {
          width: payload.width,
          height: payload.height,
        },
      };
    default:
      return state;
  }
};

export default ui;

export const uiSelector = state => state.ui;

export const openedModalTypeSelector = state => uiSelector(state).type;

const windowDimensionsSelector = state => uiSelector(state).windowDimensions;

export const widthSelector = state => windowDimensionsSelector(state).width;

const heightSelector = state => windowDimensionsSelector(state).height;

export const isExtraSmallViewSelector = state => widthSelector(state) < EXTRA_SMALL_WIDTH;

export const isSmallViewSelector = state => widthSelector(state) < SMALL_WINDOW_WIDTH;

export const isMobileViewSelector = state => widthSelector(state) <= TABLET_WINDOW_WIDTH;

export const isMediumViewSelector = state => widthSelector(state) < MEDIUM_WINDOW_WIDTH;

export const isLargeViewSelector = state => widthSelector(state) < LARGE_WINDOW_WIDTH;

export const isMediumScreenWidthSelector = state => widthSelector(state) > MEDIUM_WINDOW_WIDTH && widthSelector(state) < LARGE_WINDOW_WIDTH;

export const isMediumWindowHeightSelector = state => heightSelector(state) < MEDIUM_WINDOW_HEIGHT;

