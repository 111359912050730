import { SET_SEARCH_VALUE } from '../actions/searchActions';

const initialState = {
  value: '',
};

const search = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_VALUE:
      return {
        ...state,
        value: payload,
      };
    default:
      return state;
  }
};

export default search;

export const searchSelector = state => state.search;

export const searchValueSelector = state => searchSelector(state).value;
