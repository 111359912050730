const ROUTE_PATH = {
  Signup: '/sign-up',
  OurTeam: '/who-we-are/our-team',
  WhatWeThink: '/who-we-are/what-we-think',
  ServicesAndProduces: '/who-we-are/services-and-products',
  ECatalog: '/e-catalog',
  ECatalogDetails: '/e-catalog/:alias',
  UpcomingEvents: '/whats-happening/overview',
  Statistics: '/statistics',
  OurEvents: '/whats-happening/our-events',
  EventDetails: '/event/:alias',
  MyProfile: '/my-profile',
  ConfirmAccount: '/confirm-account',
  ResetPassword: '/reset-password',
};

export default ROUTE_PATH;
