import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormGroup, TextField, Button } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import classNames from 'classnames';

import i18n from '../../i18n';
import { validateEmailValue } from '../../utils/validator';
import httpClient from '../../services/httpClient';
import endpoints from '../../services/httpClient/constants/endpoints';
import Loader from '../Loader/Loader';
import './suggestionForm.css';

const SuggestionForm = (
  {
    setShowErrorMessage,
    afterSubmitCallback = () => {},
    isDark = false,
    inCatalogsPage = false,
  }
) => {
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [lastName, setLastName] = useState('');
  const [isLastNameInvalid, setIsLastNameInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isTextInvalid, setIsTextInvalid] = useState(false);
  const [text, setText] = useState('');

  const textPlaceholder = inCatalogsPage
    ? i18n.t('e-catalog_suggestion_placeholder')
    : i18n.t('main_suggestion_placeholder');

  const isNameValid = name => {
    return !!name.trim().length;
  };

  const handleNameChange = useCallback(({ target: { value } }) => {
    setIsNameInvalid(false);
    const nameValue = value.replace(/[0-9!@#$%^&*\[\]()-_+,./';:<>"\}\{\\+=]+/g, '');

    if (nameValue.trim().length <= 250) {
      setName(nameValue);
    }
  }, []);

  const validateName = useCallback(() => {
    setIsNameInvalid(!isNameValid(name));
  }, [name]);

  const handleLastNameChange = useCallback(({ target: { value } }) => {
    setIsLastNameInvalid(false);
    const nameValue = value.replace(/[0-9!@#$%^&*\[\]()-_+,./';:<>"\}\{\\+=]+/g, '');

    if (nameValue.trim().length <= 250) {
      setLastName(nameValue);
    }
  }, []);

  const validateLastName = useCallback(() => {
    setIsLastNameInvalid(!isNameValid(lastName));
  }, [lastName]);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setIsEmailInvalid(false);

    if (value.trim().length <= 250) {
      setEmail(value);
    }
  }, []);

  const validateEmail = useCallback(() => {
    setIsEmailInvalid(!validateEmailValue(email.trim()));
  }, [email]);

  const isTextValid = text => {
    return !!text.trim().length;
  }

  const handleTextChange = useCallback(({ target: { value } }) => {
    if (value.length <= 1000) {
      setText(value);
    }
  }, []);

  const validateText = useCallback(() => {
    setIsTextInvalid(!text.trim().length);
  }, [text]);

  const triggerValidations = useCallback(() => {
    validateName();
    validateLastName();
    validateEmail();
    validateText();
  }, [validateName, validateLastName, validateEmail, validateText]);

  const handleSubmit = useCallback(async e => {
    e.preventDefault();

    setShowErrorMessage(false);
    // trigger validations to show invalid states
    // after submitting form
    triggerValidations();

    if (
      isNameValid(name)
      && isNameValid(lastName)
      && validateEmailValue(email.trim())
      && isTextValid(text.trim())
    ) {
      try {
        setIsLoading(true);

        const data = {
          first_name: name.trim(),
          last_name: lastName.trim(),
          email,
          content: text.trim(),
          page: pathname,
        };

        await httpClient.post(endpoints.suggestions, data);
        afterSubmitCallback();
      } catch (error) {
        setShowErrorMessage(true);
        // handle error case
        setIsLoading(false);
      }
    }
  }, [
    triggerValidations,
    pathname,
    name,
    lastName,
    email,
    text,
    afterSubmitCallback,
    setShowErrorMessage,
  ]);

  return (
    <div className="flex flex-col">
      <form>
        <div className="flex">
          <FormGroup
            classes={{
              root: 'w-full mb-4 mr-4',
            }}
          >
            <label
              htmlFor="suggestionName"
              className="text-primary-700 mb-1"
            >
              {i18n.t('suggestion_name')}
            </label>
            <TextField
              variant="outlined"
              size="small"
              classes={{
                root: `form-input suggestion-input ${isNameInvalid ? 'form-input__invalid' : ''} ${isDark ? 'suggestion-input__dark' : ''}`,
                popper: 'shadow-md',
              }}
              id="suggestionName"
              value={name}
              onChange={handleNameChange}
              onBlur={validateName}
            />
          </FormGroup>
          <FormGroup
            classes={{
              root: 'w-full mb-4 ml-4',
            }}
          >
            <label
              htmlFor="suggestionLastName"
              className="text-primary-700 mb-1"
            >
              {i18n.t('suggestion_lastname')}
            </label>
            <TextField
              variant="outlined"
              size="small"
              id="suggestionLastName"
              classes={{
                root: `form-input suggestion-input ${isLastNameInvalid ? 'form-input__invalid' : ''} ${isDark ? 'suggestion-input__dark' : ''}`,
                popper: 'shadow-md',
              }}
              value={lastName}
              onChange={handleLastNameChange}
              onBlur={validateLastName}
            />
          </FormGroup>
        </div>
        <FormGroup
          classes={{
            root: 'w-full mb-6',
          }}
        >
          <label
            htmlFor="suggestionEmail"
            className="text-primary-700 mb-1"
          >
            {i18n.t('suggestion_email')}
          </label>
          <TextField
            variant="outlined"
            size="small"
            id="suggestionEmail"
            classes={{
              root: `form-input suggestion-input ${isEmailInvalid ? 'form-input__invalid' : ''} ${isDark ? 'suggestion-input__dark' : ''}`,
              popper: 'shadow-md',
            }}
            value={email}
            onChange={handleEmailChange}
            onBlur={validateEmail}
          />
        </FormGroup>
        <FormGroup
          classes={{
            root: 'w-full mb-6',
          }}
        >
          <TextareaAutosize
            aria-label={i18n.t('suggestion_text')}
            minRows={3}
            value={text}
            onChange={handleTextChange}
            placeholder={textPlaceholder}
            onBlur={validateText}
            className={classNames('suggestion-textarea rounded-md', {
              'form-input__invalid': isTextInvalid,
              'suggestion-textarea__dark': isDark
            })}
          />
        </FormGroup>
        <div className="flex justify-end">
          <Button
            variant="outlined"
            type="submit"
            size="small"
            aria-label="suggestion input"
            classes={{
              root: 'button-primary'
            }}
            onClick={handleSubmit}
          >
            {i18n.t('suggestion_button')}
            {isLoading && <Loader className="ml-2" />}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SuggestionForm;
