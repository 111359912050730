import {
  SET_STATISTICS,
  SET_STATISTICS_ERROR,
  SET_STATISTICS_LOADING,
} from "../actions/statisticsActions";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const statistics = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STATISTICS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_STATISTICS:
      return {
        ...state,
        data: payload,
      };
    case SET_STATISTICS_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default statistics;

export const statisticsSelector = state => state.statistics;
