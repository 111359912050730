import { memo } from 'react';
import PropTypes from 'prop-types';

import './loader.css';

const Loader = (
  {
    diameter,
    classNames,
    borderWidth,
    transparent,
  }
) => {

  return (
    <span
      className={`loader align-middle animate-spin rounded-full inline-block ${classNames}`}
      style={{
        '--loader-size': diameter === 'full' ? '100%' : `${diameter}px`,
        '--loader-border-width': `${borderWidth}px`,
        '--loader-main-color': transparent ? 'transparent' : 'var(--color-secondary-300)', // '#3AA3B4',
      }}
    />
  );
};

Loader.defaultProps = {
  diameter: 20,
  borderWidth: 2,
  transparent: false,
};

Loader.propTypes = {
  diameter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  transparent: PropTypes.bool,
  classNames: PropTypes.string,
  borderWidth: PropTypes.number,
};

export default memo(Loader);
